import * as React from "react";
import { List, Datagrid, TextField, ReferenceField, BooleanField, FunctionField, SearchInput, SelectInput, TextInput } from 'react-admin';
import { ListaRuoliUtenti } from '../generale';

export const UtentiList = () => {

    const filters = [
        // <SearchInput source="email_cs" alwaysOn />,
        <TextInput source="nominativo_cs" label="Cerca per nominativo" alwaysOn />,
        <TextInput source="email_cs" label="Cerca per email" alwaysOn />,
        <SelectInput
            alwaysOn
            source="stato"
            label="Stato"
            choices={[
                { id: 1, stato: 'Attivi' },
                { id: 0, stato: 'Disattivi' }
            ]}
            optionText="stato"
            optionValue="id"
        />,
        <ListaRuoliUtenti alwaysOn />
    ];

    React.useEffect(() => {
        document.title = "Lista utenti";
    }, []);

    return (
        <List filters={filters} exporter={false}>
            <Datagrid bulkActionButtons={false} rowClick="edit">
                <TextField source="id" />
                <FunctionField render={record => (
                    (record.rag_soc !== null && record.rag_soc !== '' ? `${record.rag_soc}` : `${record.nominativo}`)
                )} label="Nominativo" />
                <TextField source="username" />
                <FunctionField render={record => (
                    (record.comune !== null && record.comune !== '' ? `${record.comune}` : `-`)
                )} label="Città" />
                <TextField source="telefono" />
                <TextField source="email" />
                <ReferenceField source="id_ruolo" reference="utenti_ruoli" label="Ruolo" link={false}>
                    <TextField source="descr" />
                </ReferenceField>
                <BooleanField source="stato" />
            </Datagrid>
        </List>
    )
};