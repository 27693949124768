import * as React from 'react';
import { Alert, Typography, useMediaQuery } from '@mui/material';
import { ScreenRotation } from '@mui/icons-material';

export const RuotaDevice = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <>
            {isSmall ? (
                <Alert icon={<ScreenRotation />} variant="filled" severity="warning" sx={{ my: 1, py: 0 }}>
                    <Typography variant="p" sx={{lineHeight: 1, fontSize: 16}}>Ruota il dispositivo per visualizzare più informazioni</Typography>
                </Alert>
            ) : null}
        </>
    )
}