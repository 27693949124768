import * as React from "react";
import { Create, SimpleForm, TextInput, useGetOne, required } from 'react-admin';
import { useParams } from "react-router-dom";
import { Typography } from '@mui/material';

export const FaqNuovo = () => {

    const TitoloSezione = () => {
        return <span>Creazione di una Faq</span>;
    };

    React.useEffect(() => {
        document.title = "Creazione di una FAQ";
    }, []);


    return (
        <Create resource="faq" title={<TitoloSezione />} redirect="list">
            <SimpleForm>
                <Typography variant="h4">Creazione di una Faq</Typography>
                <TextInput source="domanda" fullWidth multiline validate={[required()]} />
                <TextInput source="risposta" fullWidth multiline validate={[required()]} />
            </SimpleForm>
        </Create>
    )
};