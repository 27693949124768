import * as React from "react";
import { Edit, SimpleForm, TextInput, useGetOne, required } from 'react-admin';
import { useParams } from "react-router-dom";
import { Typography } from '@mui/material';
import moment from 'moment';


export const FaqModifica = () => {
    const { id } = useParams();

    const TitoloSezione = () => {
        return <span>Modifica della Faq: <b>{`"(ID: ${id})"`}</b></span>;
    };

    React.useEffect(() => {
        document.title = "Modifica la FAQ";
    }, []);


    return (
        <Edit title={<TitoloSezione />}>
            <SimpleForm>
                <Typography variant="h4">Modifica della Faq: {id}</Typography>
                <TextInput source="domanda" fullWidth multiline validate={[required()]} />
                <TextInput source="risposta" fullWidth multiline validate={[required()]} />
            </SimpleForm>
        </Edit>
    )
};