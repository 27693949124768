import { apiFullUrl, idUtentiReteDiretta } from "./components/generale";

const authProvider = {
    // called when the user attempts to log in
    login: ({ username, password }) => {
        // localStorage.setItem('username', username);
        const request = new Request(apiFullUrl + '/login', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                // localStorage.setItem('auth', JSON.stringify(auth));
                localStorage.setItem('auth_uid', auth.id);
                localStorage.setItem('permissions', auth.id_ruolo);
                localStorage.setItem('auth_api_key', auth.api_key);
            })
            .catch(() => {
                throw new Error('Network error')
            });
        // return Promise.resolve();
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('auth_uid');
        localStorage.removeItem('permissions');
        localStorage.removeItem('auth_api_key');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth_uid');
            localStorage.removeItem('permissions');
            localStorage.removeItem('auth_api_key');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('auth_uid')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        const reteDiretta = (idUtentiReteDiretta.includes(parseInt(localStorage.getItem('auth_uid'))) ? '_rd' : '');
        return role ? Promise.resolve(role + reteDiretta) : Promise.reject();
    }
};

export default authProvider;